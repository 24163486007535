<template>
  <div class="add-friends-list">
    <div :class="[classA, classB]" v-if="tipShow">
      <img class="icon-warn" src="@/assets/images/icon-warning.png" alt="" />
      <span class="tip-text"
        >点击添加，可复制手机号快速跳转到添加页面
        <template v-if="classB">({{ tipCountdown }}s)</template>
      </span>
      <img class="icon-close" src="@/assets/images/icon-close.png" alt="" @click="tipClose" />
    </div>
    <van-tabs ref="wxbTabs" v-model="active" sticky swipeable @change="change">
      <van-tab>
        <template #title>
          <div class="tab-title">
            <div class="number">{{ numberTotal.toAddNum }}</div>
            <div>待添加</div>
          </div>
        </template>
        <div class="list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-if="addFriendsList.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="100">
              <div @click="getUserInfo(item.id)" class="addTask-card" v-for="(item, index) of addFriendsList" :key="index">
                <div class="person-detail">
                  <div class="person-phone">{{ item.name || "暂无姓名" }}</div>
                  <div class="person-name">{{ item.phone || "暂无手机号" }}</div>
                </div>
                <van-button size="small" class="btn" @click.stop="addFriedns(item)">添加</van-button>
              </div>
            </van-list>
            <wxb-no-data v-else />
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <div class="tab-title">
            <div class="number">{{ numberTotal.toPassNum }}</div>
            <div>待通过</div>
          </div>
        </template>
        <div class="list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-if="addFriendsList.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="100">
              <div @click="getUserInfo(item.id)" class="addTask-card" v-for="(item, index) of addFriendsList" :key="index">
                <div class="person-detail">
                  <div class="person-phone">{{ item.name || "暂无姓名" }}</div>
                  <div class="person-name">{{ item.phone || "暂无手机号" }}</div>
                </div>
                <van-button size="small" class="btn" @click.stop="addFriedns(item)">再次邀请</van-button>
              </div>
            </van-list>
            <wxb-no-data v-else />
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <div class="tab-title">
            <div class="number">{{ numberTotal.addedNum }}</div>
            <div>已添加</div>
          </div>
        </template>
        <div class="list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-if="addFriendsList.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="100">
              <div class="addTask-card" v-for="(item, index) of addFriendsList" :key="index">
                <div class="person-detail">
                  <div class="person-phone">{{ item.name || "暂无姓名" }}</div>
                  <div class="person-name">{{ item.phone || "暂无手机号" }}</div>
                </div>
                <van-button size="small" class="btn" @click="openChat(item.clueId)">聊天</van-button>
              </div>
            </van-list>
            <wxb-no-data v-else />
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>

    <!-- 详情弹窗S -->
    <van-action-sheet class="van-action-tag" v-model="show" @click-overlay="cancel">
      <div class="wxb-pop-btn">
        <div class="pop-cancel-btn" @click="cancel">取消</div>
        <div class="pop-title">详细信息</div>
        <div class="pop-cancel-btn"></div>
      </div>
      <div class="content">
        <div class="info-detail" v-for="(item, index) of lableList" :key="index">
          <span class="lable">{{ item.label }}</span>
          <span class="colon">：</span>
          <span class="label-val">{{ userInfo[item.value] }}</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 详情弹窗E -->
  </div>
</template>

<script>
import addFriendsList from '@/api/addFriendsList';
import clue from '@/api/clue';
import { wxAgentConfig } from '@/util/util';
import { Toast } from 'vant';

const lableList = [
  { label: '姓名', value: 'name' },
  { label: '手机号', value: 'phone' },
  { label: '性别', value: 'gender' },
  { label: '地区', value: 'district' },
  { label: '留资时间', value: 'interactTime' },
  { label: '留资项目', value: 'interactEstate' },
  { label: '客户来源', value: 'channelName' },
  { label: '意向级别', value: 'intentionLevel' },
  { label: '备注说明', value: 'remark' },
];
export default {
  name: 'addFriendsList',
  data() {
    return {
      lableList,
      tipShow: false, //tip显示
      classA: 'tip',
      classB: '',
      tipCountdown: 4,
      active: 0,
      // 卡片分页
      pageNation: {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      },
      loading: false,
      finished: false,
      refreshing: false,
      addFriendsList: [],
      numberTotal: {
        toAddNum: 0,
        toPassNum: 0,
        addedNum: 0,
      },
      show: false,
      userInfo: {},
    };
  },
  methods: {
    cancel() {
      this.show = false;
      this.userInfo = {};
    },
    //获取人员详情
    async getUserInfo(id) {
      let params = { id };
      let { code, data } = await addFriendsList.getAddfriendInfo(params);
      if (code === '0') {
        this.show = true;
        this.userInfo = data;
      }
    },
    tipClose() {
      clearInterval(this.tipTimer);
      this.classA = 'tip-in';
      this.classB = '';
    },
    countDown() {
      this.tipTimer = setInterval(() => {
        this.tipCountdown--;
        if (this.tipCountdown === 3) {
          this.classB = 'tip-after';
        }
        if (this.tipCountdown === 0) {
          clearInterval(this.tipTimer);
          this.classA = 'tip-in';
          this.classB = '';
        }
      }, 1000);
    },
    //添加好友
    async addFriedns(task) {
      //如果需要回调：
      this.$copyText(task.phone).then(
        async () => {
          Toast('复制成功');
          //刷新统计数
          let { code, data } = await addFriendsList.userAddFriend({ id: task.id, taskId: this.$route.query.taskId });
          if (code === '0') {
            if (data) {
              this.addFriendsList = [];
              this.pageNation = {
                pageNo: 0,
                pageSize: 20,
                totalRecords: 0,
              };
              await this.getUserExecute();
              await this.getUserPage();
              let that = this;
              let fn = () => {
                this.$wx.invoke('navigateToAddCustomer', {}, (res) => {
                  console.log(res, '打开新增客户api回调');
                  if (res.err_msg.indexOf('no permission') !== -1) {
                    Toast('请开通添加客户权限');
                  }
                });
              };
              wxAgentConfig(fn, that, ['navigateToAddCustomer']);
            } else {
              this.addFriendsList = [];
              this.pageNation = {
                pageNo: 0,
                pageSize: 20,
                totalRecords: 0,
              };
              await this.getUserExecute();
              await this.getUserPage();
              Toast('当前客户已成为好友，请勿重复添加');
            }
          }
        },
        function() {
          Toast('复制失败');
        }
      );
    },
    //打开企业微信
    async openChat(clueId) {
      let params = { clueId, opId: localStorage.getItem('platform_opId') };
      let { code, data, msg } = await clue.getOtherClue(params);
      if (code !== '0' || !data) {
        console.error('接口报错', msg);
        return;
      }
      this.$wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: '', //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: data.externalUserId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function(res) {
          console.log(res, '======>>>>>群聊id');
          // 回调
        },
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
    },
    //获取人员统计
    async getUserExecute() {
      let params = {
        taskId: this.$route.query.taskId,
      };
      let { code, data } = await addFriendsList.getUserExecute(params);
      if (code === '0') {
        this.numberTotal = data;
      }
    },
    //获取添加人员列表
    async getUserPage() {
      let params = {
        taskId: this.$route.query.taskId,
        status: this.active,
        pageNo: this.pageNation.pageNo,
        pageSize: this.pageNation.pageSize,
      };
      let { code, data } = await addFriendsList.getUserPage(params);
      this.loading = false;
      if (code === '0') {
        this.addFriendsList = this.addFriendsList.concat(data.data);
        this.pageNation.totalRecords = data.totalRecords;
        if (this.addFriendsList.length >= this.pageNation.totalRecords) {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
    },
    //下拉刷新
    onRefresh() {
      this.pageNation.pageNo = 0;
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.addFriendsList = [];
        this.refreshing = false;
      }
      this.pageNation.pageNo += 1;
      this.getUserPage();
    },
    //标签选择
    change() {
      this.addFriendsList = [];
      this.pageNation = {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      };
      this.getUserExecute();
      this.onLoad();
    },
  },
  mounted() {
    this.onLoad();
    this.getUserExecute();
    this.tipShow = true;
    this.countDown();
  },
};
</script>

<style lang="less" scoped>
.icon-warn {
  width: 16px;
  height: 16px;
}
@keyframes down {
  from {
    top: -40px;
  }
  to {
    top: 2px;
  }
}

@keyframes in {
  from {
    top: 2px;
  }
  to {
    top: -40px;
  }
}
.tip-in {
  display: flex;
  align-items: center;
  position: fixed;
  top: -40px;
  left: 8px;
  z-index: 3;
  background: #fff;
  color: @gary-wxb-black-1;
  width: 359px;
  height: 40px;
  border-radius: 4px;
  line-height: 44px;
  padding: 4px 8px;
  box-sizing: border-box;
  overflow: hidden;
  animation: in 0.3s ease-in;
  .tip-text {
    margin-left: 4px;
    font-size: 12px;
    color: #303133;
    line-height: 17px;
  }
  .icon-close {
    width: 16px;
    height: 16px;
    margin-left: auto;
  }
}
.tip {
  display: flex;
  align-items: center;
  position: fixed;
  top: 2px;
  left: 8px;
  z-index: 3;
  background: #fff;
  color: @gary-wxb-black-1;
  width: 359px;
  height: 40px;
  box-shadow: 0px 2px 8px 0px rgba(48, 49, 51, 0.16);
  border-radius: 4px;
  line-height: 44px;
  padding: 4px 8px;
  box-sizing: border-box;
  animation: down 0.3s ease-out;
  overflow: hidden;
  .tip-text {
    margin-left: 4px;
    font-size: 12px;
    color: #303133;
    line-height: 17px;
  }
  .icon-close {
    width: 16px;
    height: 16px;
    margin-left: auto;
  }
}
.add-friends-list {
  .tab-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .number {
      font-size: 14px;
      font-family: SSI_Black;
      line-height: 17px;
      margin-bottom: 2px;
    }
  }
  .list {
    padding-top: 12px;
    .addTask-card {
      background: #ffffff;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &::before {
        position: absolute;
        right: 0px;
        bottom: 0px;
        content: "";
        width: calc(100% - 16px);
        height: 1px;
        background: #f6f6f6;
      }
      .person-detail {
        text-align: left;
        .person-phone {
          font-size: 15px;
          font-weight: 500;
          color: #303133;
          line-height: 21px;
          margin-bottom: 2px;
        }
        .person-name {
          font-size: 12px;
          color: @gray9;
          line-height: 17px;
        }
      }
      .btn {
        padding: 8px 18px;
        background: #f0f4fe;
        border-radius: 4px;
        font-size: 12px !important;
        line-height: 17px;
        color: @blue;
      }
    }
  }
}
</style>

<style lang="less">
.add-friends-list {
  .van-action-tag {
    .content {
      padding: 24px 16px 52px 16px;
      .info-detail {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
        .lable {
          color: #909399;
          width: 70px;
          display: inline-block;
          text-align: justify;
          text-align-last: justify;
          &:after {
            content: "";
            width: 100%;
          }
        }
        .colon {
          color: #909399;
        }
        .label-val {
          color: #303133;
        }
      }
    }
  }
}
</style>
