import request from '../../util/request';
import { CLUE } from '../apiConst';

export default {
  // 员工执行明细(统计总条数)
  getUserExecute(params) {
    return request.get({ url: `${CLUE}/task/add-friend/v1/task-detail/user-execute-card`, params: { ...params, estateId: localStorage.getItem('platform_estateId') }, hideLoading: true });
  },
  // 分页员工执行明细
  getUserPage(data) {
    return request.post({ url: `${CLUE}/task/add-friend/v1/task-detail/user-execute-page`, data: { ...data, estateId: localStorage.getItem('platform_estateId') } });
  },
  // 员工执行添加好友动作
  userAddFriend(data) {
    return request.post({ url: `${CLUE}/task/add-friend/v1/task-detail/user-addfriend`, data: { ...data, estateId: localStorage.getItem('platform_estateId') }, hideLoading: true });
  },
  // 查询添加好友详情数据
  getAddfriendInfo(params) {
    return request.get({ url: `${CLUE}/task/add-friend/v1/task-detail/get-addfriend-info`, params });
  },
};
